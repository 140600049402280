import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import './accueil.scss'
import SliderAccueil from '../components/slider-accueil'
import SEO from '../components/seo'
import CarouselVoyagesAccueil from '../components/carousel-voyages-accueil'
import CarouselEscapadesAccueil from '../components/carousel-escapades-accueil'

export default function Accueil() {

    return (
        <div>
            <Layout>
                <SEO title="Accueil | Cité de l'évasion" />

                <div id="accueil" className="page_accueil">
                    
                    <div className="module_1">
                        <SliderAccueil />
                        <div className="sous_slide">
                            <h1>Cité de l'Évasion</h1>
                        </div>
                    </div>

                    <section className="module_info">
                        <div className="textes relative width_1200 flex_wrap">
                            <div className="width_50">
                                <h2>La Cité de l’Evasion <span className="second_color">déménage</span></h2>
                                <p>
                                    À partir du 26 juillet, notre adresse sera la suivante :<br />
                                    ZAE Le Pontet – 117 allée des Érables<br />
                                    73420 DRUMETTAZ CLARAFOND
                                </p>
                                <p>
                                    Numéro de téléphone et adresse mail inchangés.<br />
                                    Au plaisir de vous retrouver dans notre nouveau bureau !!!<br />
                                    À très bientôt !
                                </p>
                            </div>
                            <div className="width_50">
                                <iframe 
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11154.360516726982!2d5.9124817!3d45.6590522!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3487042e217ea465!2sCit%C3%A9%20de%20l&#39;Evasion!5e0!3m2!1sfr!2sfr!4v1630705736695!5m2!1sfr!2sfr"
                                    title="Google Maps Cité de l'évasion"
                                    width="100%"
                                    height="500"
                                    frameBorder="0"
                                    style={{border:0}}
                                    allowfullscreen=""
                                    aria-hidden="false">
                                </iframe>
                            </div>
                        </div>
                    </section>

                    <section className="module_2 width_1200">
                        <div className="textes relative width_1200">
                            <p className="before_title">Autour du monde</p>
                            <div className="separator" />
                            <h2>Il est temps de <span className="second_color">Voyager</span></h2>
                        </div>
                        <CarouselVoyagesAccueil />
                        <Link to="/voyages" className="link">Voir tous nos Voyages</Link>
                    </section>

                    <section className="module_3 relative">
                        <div className="textes relative width_1200">
                            <p className="before_title">L'agence</p>
                            <div className="separator" />
                            <h2>
                                Avec nous<br />
                                <span className="second_color">Découvrez le Monde</span>
                            </h2>
                            <p>
                                La Cité de l’Evasion, fondée en 1994, est une agence spécialisée dans 
                                le voyage de groupes.
                            </p>
                            <p>
                                Depuis sa création, de nombreuses amicales, associations et groupes
                                d’amis nous font confiance. Une équipe de professionnels est à votre
                                service pour vous faire découvrir le monde, en avion, en bateau ou
                                en autocar avec son partenaire <a href="https://www.abdvoyages.com/" target="_blank" rel="noreferrer">ABD Voyages</a>.
                            </p>
                            <p>
                                <b>Avec la Cité de l’Evasion, voyager rime avec qualité :</b><br />
                                - Nos prestataires sont sélectionnés avec rigueur et nous nous
                                engageons à vous concocter le voyage dont vous rêvez.
                            </p>
                            <p>
                                <b>Toutes destinations, toutes saisons et tous budgets:</b><br />
                                - Culture, détente, découverte, gastronomie, séminaire...
                            </p>
                            <p>
                                C’est vous qui choisissez !
                            </p>
                            <p>
                                N’hésitez pas à contacter Nathalie pour vos projets d'évasion.
                            </p>
                            <p>
                                A très bientôt.
                            </p>
                        </div>
                        <div className="video_background">
                            <div className="video_foreground">
                                <iframe
                                    title="Vue de la mer avec musique douce-vidéos libres de droits" 
                                    width="100%"
                                    height="400px"
                                    src="https://www.youtube.com/embed/6-hUQHdGuGI?controls=0&mute=1&fs=0&autoplay=1&disablekb=1&loop=1&showinfo=0&autohide=1&playlist=6-hUQHdGuGI"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                >
                                </iframe>
                            </div>
                        </div>
                        <div className="overlay_absolute_8" />
                    </section>

                    <section className="module_4 width_1200">
                        <div className="textes relative width_1200">
                            <p className="before_title">Petit tour en France</p>
                            <div className="separator" />
                            <h2>Il est temps de visiter notre <span className="second_color">Région</span></h2>
                            <p>Voici quelques idées de sorties d'une journée en groupe</p>
                        </div>
                        <CarouselEscapadesAccueil />
                        <Link to="/sorties" className="link">Voir toutes nos sorties</Link>
                    </section>
                </div>
            </Layout>
        </div>
    )
}