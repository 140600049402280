import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import './carousel-voyages-accueil.scss'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from '@fortawesome/free-solid-svg-icons'

export default function CarouselVoyagesAccueil() {
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 700,
        autoplaySpeed: 5000,
        autoplay: true,
        pauseOnHover: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
    }

    const data = useStaticQuery(graphql`
    {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "voyages-post"}}}, sort: {fields: [frontmatter___date], order: DESC}) {
          nodes {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(locale: "fr", fromNow: true)
              title
              pays
              featuredimage
              tarif
              promo
              pourcentpromo
              datevoyage
              coeur
              complet
            }
          }
        }
      }
  `)
    return (
        <div id="carousel_voyages_accueil">
            <Slider {...settings}>
                {data.allMarkdownRemark.nodes.map(post => {
                const title = post.frontmatter.title || post.fields.slug

                return (
                    <div className="container section_articles">
                    <li key={post.fields.slug}>
                        <article
                        className="post-list-item"
                        itemScope
                        itemType="http://schema.org/Article"
                        >
                        {post.frontmatter.promo && <div className="promo">- {post.frontmatter.pourcentpromo} %</div>}
                        {post.frontmatter.coeur && <FontAwesomeIcon icon={faHeart} className="coeur" />}
                        <div className="article">
                            <Link to={post.fields.slug} itemProp="url">
                                <div className="textes_article">
                                  <h2>
                                      <Link to={post.fields.slug} itemProp="url">
                                      <span itemProp="headline">{title}</span>
                                      </Link>
                                  </h2>
                                  <div className="separator relative"></div>
                                  <p className="pays relative">{post.frontmatter.pays}</p>
                                  <div className="hover">
                                    <p>Date de la sortie</p>
                                    <p className="date_voyage relative">{post.frontmatter.datevoyage}</p>
                                  </div>
                                      <p className="tarif relative">dès <span className="prix">{post.frontmatter.tarif}</span> TTC</p>
                                  </div>
                                  <img src={post.frontmatter.featuredimage} alt={post.frontmatter.altimage} />
                                  {post.frontmatter.complet && <div className="background_gradient_complet"><span>Complet</span></div>}
                                <div className="background_gradient"></div>
                            </Link>
                        </div>
                        </article>
                    </li>
                    </div>
                )
                })}
            </Slider>
        </div>
    )
}