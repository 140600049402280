import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './slider-accueil.scss'

const SliderAccueil = () => {
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 700,
        autoplaySpeed: 9000,
        autoplay: true,
        pauseOnHover: false,
    }

    return(
        <div className="slider_accueil">
            <Slider {...settings}>
                <div className="slide slide_1">
                </div>
                <div className="slide slide_2">
                </div>
                <div className="slide slide_3">
                </div>
            </Slider>
        </div>
    )
}

export default SliderAccueil